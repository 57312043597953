<template>
  <b-modal
    id="modal-view-invoice"
    ok-only
    cancel
    ok-title="Fechar"
    centered
    size="xl"
    title="Fatura"
  >
    <section class="invoice-preview-wrapper" v-if="invoice.tenant">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <h6 class="mb-25 font-weight-bolder">Westsoftware Tecnologia, Desenvolvimento e Inovação LTDA</h6>
                  <p class="card-text mb-25">Rua Uruguaiana, 701 - Canadá, Cascavel - PR | 85813-660</p>
                  <p class="card-text mb-25">contato@westcob.com.br</p>
                  <p class="card-text mb-0"><a href="https://westcob.com.br/" target="_blank">www.westcob.com.br</a></p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Fatura
                    <span class="invoice-number">#{{ invoice.code }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Data de emissão:</p>
                    <p class="invoice-date">{{ invoice.date_issue | dateFormat }}</p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Data de Vencimento:</p>
                    <p class="invoice-date">{{ invoice.date_due | dateFormat }}</p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing"/>

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">Fatura para:</h6>
                  <h6 class="mb-25 font-weight-bolder">{{ invoice.tenant.company_name }}</h6>
                  <p class="card-text mb-25">
                    {{
                      invoice.tenant.street +
                      ", " +
                      invoice.tenant.number +
                      " - " +
                      invoice.tenant.neighborhood +
                      ", " +
                      invoice.tenant.city +
                      " - " +
                      invoice.tenant.state +
                      " | " +
                      invoice.tenant.zip_code
                    }}
                  </p>
                  <p class="card-text mb-25">{{ invoice.tenant.phone }}</p>
                  <p class="card-text mb-0">{{ invoice.tenant.email }}</p>
                </b-col>

              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-simple
              responsive
              striped
              stacked="md"
              noDataText="Você ainda não possui clientes cadastrados."
              class="text-center mt-2 mb-2"
            >
              <b-thead class="text-center">
                <b-th> Descrição </b-th>              
                <b-th> Qtd. de uso </b-th>
                <b-th> Valor </b-th>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Valor Fixo</b-td>
                  <b-td> - </b-td>
                  <b-td>{{invoice.value_fixed | moneyFormat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Valor Email</b-td>
                  <b-td>{{invoice.amount_email}}</b-td>
                  <b-td>{{invoice.value_email | moneyFormat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Valor SMS</b-td>
                  <b-td>{{invoice.amount_sms}}</b-td>
                  <b-td>{{invoice.value_sms | moneyFormat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Valor WhatsApp</b-td>
                  <b-td>{{invoice.amount_whats}}</b-td>
                  <b-td>{{invoice.value_whats | moneyFormat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Valor Ligação</b-td>
                  <b-td>{{invoice.amount_call}}</b-td>
                  <b-td>{{invoice.value_call | moneyFormat }}</b-td>
                </b-tr>                
              </b-tbody>
            </b-table-simple>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Valor Total:</p>
                      <p class="invoice-total-amount">
                        {{ invoice.value_total | moneyFormat }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Desconto:</p>
                      <p class="invoice-total-amount">-</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        {{ invoice.value_total | moneyFormat }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <!-- Button: Download -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              target="_blank"
              :href="invoice.link_invoice"
            >
              Download
            </b-button>

            <!-- Button: Edit -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
            >
              Editar
            </b-button>

            <!-- Button: Add Payment -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              @click="addPayment"
              v-if="(invoice.status != 'Pago') && !paidInvoice"
            >
              Pagar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mb-75"
              block
              @click="removePayment"
              v-else
            >
              Remover pagamento
            </b-button>

          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import { ref, onUnmounted } from '@vue/composition-api'
import Logo from "@core/layouts/components/LogoText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BButton,
    BModal,
    Logo,
  },
  data() {
    return {
      id: this.view,
      CloseModal: null,
      paidInvoice: false,
    };
  },
  computed: {
    ...mapState("invoices", ["invoice"]),
  },
  methods: {
    ...mapActions("invoices", ["fetchInvoice", "updateInvoice"]),

    // Mark Invoice as Paid
    addPayment() { 
      this.updateInvoice({
        id: this.id,
        status: "Pago"
      })
      this.paidInvoice = true
    },
    removePayment() {
      this.updateInvoice({
        id: this.id,
        status: "Aberto"
      })
      this.paidInvoice = false
    }
  },
  created() {
    this.fetchInvoice({
      invoice_id: this.id,
    });
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  props: {
    view: {
      default: function () {
        return [];
      },
    },
  },
};
</script>
 <style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
