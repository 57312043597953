<template>
  <b-card>
    <div>
      <b-row>
        <b-col>
          <b-button variant="relief-danger" class="mb-1" v-b-modal.modal-fatura>
            Gerar Faturas
          </b-button>
        </b-col>
        <b-col md="6" xl="2 p-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Pesquisar"
              v-model="search"
              debounce="800"
              type="search"
            />
          </b-input-group>
        </b-col>
        <b-col md="6" xl="2 p-1">
          <b-form-select v-model="StatusFilter" :options="options" />
        </b-col>
      </b-row>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
        <b-table-simple
          striped
          stacked="md"
          noDataText="Você ainda não possui clientes cadastrados."
          class="mt-2 responsive text-center"
          id="tableInvoices"
          :item="fetchInvoice"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead class="text-center">
            <b-th>#</b-th>
            <b-th>Clientes</b-th>
            <b-th>Status</b-th>
            <b-th>Valor</b-th>
            <b-th>Data de emissão</b-th>
            <b-th>Data de vencimento</b-th>
            <b-th>Ação</b-th>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in invoices">
              <b-td> {{ tr.code }} </b-td>
              <b-td> {{ tr.tenant.company_name }} </b-td>

              <b-td v-if="tr.status == 'Pago'" class="text-center">
                <b-avatar
                  size="32"
                  variant="success"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-success
                  title="Fatura paga"
                >
                  <feather-icon icon="CheckIcon" />
                </b-avatar>
              </b-td>
              <b-td v-if="tr.status == 'Aberto'" class="text-center">
                <b-avatar
                  size="32"
                  variant="warning"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-warning
                  title="Fatura em aberto"
                >
                  <feather-icon icon="ActivityIcon" />
                </b-avatar>
              </b-td>
              <b-td v-if="tr.status == 'Atraso'" class="text-center">
                <b-avatar
                  size="32"
                  variant="danger"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-danger
                  title="Fatura pendente"
                >
                  <feather-icon icon="AlertTriangleIcon" />
                </b-avatar>
              </b-td>

              <b-td> {{ tr.value_total | moneyFormat }} </b-td>
              <b-td> {{ tr.date_issue | dateFormat }} </b-td>
              <b-td> {{ tr.date_due | dateFormat }} </b-td>
              <b-td>
                <!-- <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-view-invoice
                  @click="ViewInvoiceModal(tr.id)"

                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableInvoices"
        class="mt-1"
      />
    </div>
    <div v-if="invoices_id != '0'">
      <view-invoice :view="invoices_id" ref="viewInvoice"/>
    </div>

    <b-modal
      id="modal-fatura"
      ok-only
      ok-title="Gerar"
      centered
      size="lg"
      title="Gerador de fatura"
    >
      <b-card-text>
        <b-alert variant="warning" show>
          <h4 class="alert-heading">Atenção!</h4>
        </b-alert>
        <hr />
        <b-form-checkbox v-model="selectedModal" value="gerar">
          Quero gerar as faturas
        </b-form-checkbox>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";
import { BButton, BModal, VBModal } from "bootstrap-vue";
import ViewInvoice from "@/components/negotiation/ViewInvoice";

export default {
  components: {
    ViewInvoice,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      search: null,

      isLoading: true,
      invoices_id: 0,

      selectedModal: [],
      StatusFilter: null,
      options: [
        { text: "Status", value: null },
        { value: "Pago", text: "Pago" },
        { value: "Aberto", text: "Em aberto" },
        { value: "Atraso", text: "Pendente" },
      ],
    };
  },
  computed: mapState("invoices", ["invoices", "pagination"]),
  methods: {
    ViewInvoiceModal(id){
      this.invoices_id = id; 
      this.$refs.viewInvoice.fetchInvoice({
        invoice_id: this.invoices_id,
      })
    },
    fetchInvoice(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("invoices/fetchInvoices", {
          paginated: paginated,
          page: page,
          generic: this.search,
          status: this.StatusFilter,
          perPage: this.perPage,
        })

        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchInvoice(true, value);
    },
  },
  created() {
    this.fetchInvoice();
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchInvoice(true, 1);
    },
    StatusFilter(val) {
      this.currentPage = 1;
      this.fetchInvoice(true, 1);
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
